
export  const CommonUtil={

    FirstLetter:function(word){
        return word.substring(0,1);
    },

    Crop:function(e, len){
        var leng=500;

        if(len!==null){
            leng=len;
        }
        var descripholder =  e;
        var description=null;
        if(descripholder.length <=leng){
            description=descripholder;
        }else{
            description=descripholder.substring(0,leng-50)+"..."
        }

        return description;

    },
    UrlParam:function(pos){
        const urlData = window.location.pathname.split("/");

        return urlData[pos];
    }
}