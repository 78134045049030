import React from 'react';
import { useNavigate } from "react-router-dom";

//CSS
import './leftnav.css'


//BOOTSTRAP
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';

//FONT ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendarCheck, faSitemap, faUsers, faMobile, faBox, faLocationPin, faStore, faCashRegister, faQrcode} from '@fortawesome/free-solid-svg-icons';


//STORE
import userDataStore from '../../stores/zuserdatastore';

function LeftNav(){

    const history = useNavigate();
     //STORES
     const { userData} = userDataStore();

    const routeClick=(path)=>{
        history(path, {replace:true})
    }

    const checkAccess=(ut)=>{
        var rtn=false;
        ut.map((u)=>{
            if(u===userData[0].usertype){
                rtn=true;  
            }  
        })
        return rtn;
    }


    return(
        <React.Fragment>

            {((checkAccess(["OU",""]))? <Row>
                <Col>
                    <button className="icon-container" onClick={()=>{routeClick("/venues")}}>
                        <FontAwesomeIcon icon={faLocationPin} />
                        <span className="icon-container-title">Venue Management
                        <div className="icon-container-title-pointer"></div>
                        </span>
                        
                    </button>
                </Col>
            </Row>:"")}

            {((checkAccess(["OU",""]))? <Row>
                <Col>
                    <button className="icon-container" onClick={()=>{routeClick("/events")}}>
                        <FontAwesomeIcon icon={faCalendarCheck} />
                        <span className="icon-container-title">Event Management
                            <div className="icon-container-title-pointer"></div>
                        </span>
                    </button>
                </Col>
            </Row>:"")}

            {((checkAccess(["OU","EV"]))?<Row>
                <Col>
                    <button className="icon-container" onClick={()=>{routeClick("/products")}}>
                        <FontAwesomeIcon icon={faBox} />
                        <span className="icon-container-title">Products Management
                        <div className="icon-container-title-pointer"></div>
                        </span>
                        
                    </button>
                </Col>
            </Row>:"")}
            

            {((checkAccess(["OU",""]))?<Row>
                <Col>
                    <button className="icon-container" onClick={()=>{routeClick("/company")}}>
                        <FontAwesomeIcon icon={faSitemap} />
                        <span className="icon-container-title">Company Management
                        <div className="icon-container-title-pointer"></div>
                        </span>
                        
                    </button>
                </Col>
            </Row>:"")}
            

            {((checkAccess(["OU","EV"]))? <Row>
                <Col>
                    <button className="icon-container" onClick={()=>{routeClick("/users")}}>
                        <FontAwesomeIcon icon={faUsers} />
                        <span className="icon-container-title">User Management
                        <div className="icon-container-title-pointer"></div>
                        </span>
                        
                    </button>
                </Col>
            </Row>:"")}
           

            {((checkAccess(["OU","EV"]))?<Row>
                <Col>
                    <button className="icon-container" onClick={()=>{routeClick("/device")}}>
                        <FontAwesomeIcon icon={faMobile} />
                        <span className="icon-container-title">Device Management
                        <div className="icon-container-title-pointer"></div>
                        </span>
                        
                    </button>
                </Col>
            </Row>:"")}

            

            {((checkAccess(["OU","EV"]))? <Row>
                <Col>
                    <button className="icon-container" onClick={()=>{routeClick("/vendors")}}>
                        <FontAwesomeIcon icon={faStore} />
                        <span className="icon-container-title">Vendor Management
                        <div className="icon-container-title-pointer"></div>
                        </span>
                        
                    </button>
                </Col>
            </Row>:"")}

            {((checkAccess(["EV"]))? <Row>
                <Col>
                    <button className="icon-container" onClick={()=>{routeClick("/vendorpos")}}>
                        <FontAwesomeIcon icon={faCashRegister} />
                        <span className="icon-container-title">Vendor Point Of Sale
                        <div className="icon-container-title-pointer"></div>
                        </span>
                        
                    </button>
                </Col>
            </Row>:"")}

            {((checkAccess(["OU",""]))?<Row>
                <Col>
                    <button className="icon-container" onClick={()=>{routeClick("/securityscantickets")}}>
                        <FontAwesomeIcon icon={faQrcode} />
                        <span className="icon-container-title">Security Scan Tickets
                        <div className="icon-container-title-pointer"></div>
                        </span>
                        
                    </button>
                </Col>
            </Row>:"")}

           
           
           
           
        </React.Fragment>
    )


}
export default LeftNav;