import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


/*TEMP Delete */
import complogo from './companylogo.png';

//INTERNAL
import RestServer from '../cRestServer';
import {orgurl, producturl}  from '../../environment';

//WIDGETS
import CurrentRevenue from '../datawidget/currentrevenue';
import ProjectedRevenue from '../datawidget/projectedrevenue';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash, faPen, faCirclePlus} from '@fortawesome/free-solid-svg-icons';


//STORE
import userDataStore from '../../stores/zuserdatastore';

function CompanyInfo() {

    const history = useNavigate();

    //STORES
    const { userData} = userDataStore();

    const [company, setCompany]=useState();
    const [showModal, setShowModal]=useState(false);
    const [companyName, setCompanyName]=useState("");
    const [companyDescription, setCompanyDescription]=useState("");

    //TAXES
    const[taxesId, setTaxesId]=useState(null);
    const[taxesModel, setTaxesModel]=useState(false);
    const[taxes, setTaxes]=useState(null);
    const[taxName, setTaxName]=useState(null);
    const[taxDescription, setTaxDescription]=useState(null);
    const[taxAmount, setTaxAmount]=useState(null);

    //SERVICE FEE
    const[serviceFeeModel, setServiceFeeModel]=useState(false);
    const[serviceFeeName, setServiceFeeName]=useState(null);
    const[serviceFeeDescription, setServiceFeeDescription]=useState(null);
    const[serviceFeeChargeBy, setServiceFeeChargeBy]=useState(null);
    const[serviceFeeType, setServiceFeeType]=useState(null);
    const[serviceFeeAmount, setServiceFeeAmount]=useState(null);
    const[serviceFees, setServiceFee]=useState([]);
    

    //CATEGORIES
    const[categories, setCategories]=useState(null);
    const[categoryModel, setCategoryModel]=useState(false);
    const[editCategoryModel, setEditCategoryModel]=useState(false);
    const[categoryid, setCategoryid]=useState(null);
    const[categoryName, setCategoryName]=useState(null);
    const[catForDes, setCatForDes]=useState(null);
    const[categoryDescription, setCategoryDescription]=useState(null);

    const[catUserFor, setCatUserFor]=useState(null);
    const[catCode, setCatCode]=useState(null);
    


    

    

    const handleCloseModel =(e)=>{
        e.preventDefault();
        setShowModal(false);
    }

    const handleEditCategory=(e)=>{

        if(e.usedFor==="EVENT"){setCatForDes("Use For Events")};
        if(e.usedFor==="PRODUCT"){setCatForDes("Use For Products")};
         
        setCategoryid(e.categoryid);
        setCategoryName(e.categoryname);
        setCatUserFor(e.usedFor);
        setCatCode(e.categorycode);
        setCategoryDescription(e.categorydescription);
        setEditCategoryModel(true);

    }

    const handleEditTaxForm=(e)=>{

        setTaxName(e.taxName);
        setTaxDescription(e.taxDescription);
        setTaxAmount(e.percentage);
        setTaxesId(e.companyTaxId);
        setTaxesModel(true);


    }
    const handleCatDel=(e)=>{
        new RestServer().setUrl(orgurl+'/api/cat/deletecategory/'+e)
        .setMethod('DELETE')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                setCategoryModel(false);
                new RestServer().setUrl(producturl+'/api/cat/allcompanycategory/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(response=>{
                    setCategories(response.data);
                });
            }else{
                toast(rtndata.Message);
            }
        });
    }

    const handleTaxDel=(e)=>{
        new RestServer().setUrl(orgurl+'/api/org/delcompanytaxes/'+e)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                setCategoryModel(false);
                new RestServer().setUrl(orgurl+'/api/org/companytaxes/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(response=>{
                    setTaxes(response.data);
                });

            }else{
                toast(rtndata.Message);
            }
        });

    }
    const handleEditCategoryForm=(e)=>{
        e.preventDefault();

        const data={
            categoryid:categoryid,
            categoryname:categoryName,
            categorycode:catCode,
            usedFor:catUserFor,
            categorydescription:categoryDescription
        }

        new RestServer().setUrl(producturl+'/api/cat/editcategory')
        .setMethod('PUT')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                setTaxesModel(false);
                new RestServer().setUrl(producturl+'/api/cat/allcompanycategory/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(response=>{
                    setCategories(response.data);
                });
            }else{
                toast(rtndata.Message);
            }
        });

    }

    const handleCategoryForm=(c)=>{
        c.preventDefault();
        var data=null;
        

        data={

            companyid:userData[0].companyid,
            categoryname:categoryName,
            categorycode:catCode,
            usedFor:catUserFor,
            categorydescription:categoryDescription,
        }
        console.info(data);

        new RestServer().setUrl(producturl+'/api/cat/category')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                setTaxesModel(false);
                new RestServer().setUrl(producturl+'/api/cat/allcompanycategory/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(response=>{
                    setCategories(response.data);
                });

                
                
            }else{
                toast(rtndata.Message);
            }
        });
        
    }

    const handleTaxForm=(e)=>{
        e.preventDefault();

        var data=null;

        //check if 
        if(taxesId===null){
            data={
                companyTaxId:"",
                companyId:userData[0].companyid,
                taxName:taxName,
                taxDescription:taxDescription,
                percentage:taxAmount
            }

        }else{
            data={
                companyTaxId:taxesId,
                companyId:userData[0].companyid,
                taxName:taxName,
                taxDescription:taxDescription,
                percentage:taxAmount
            }
        }        
        new RestServer().setUrl(orgurl+'/api/org/companytax')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                setTaxesModel(false);
                new RestServer().setUrl(orgurl+'/api/org/companytaxes/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(response=>{
                    setTaxes(response.data);
                });
            }else{
                toast(rtndata.Message);
            }
        });

    }

    const handleServiceFeeForm =(e)=>{
        e.preventDefault();
        const data={
            companyId:userData[0].companyid,
            serviceFeeName:serviceFeeName,
            serviceFeeDescription:serviceFeeDescription,
            serviceFeeChargeBy:serviceFeeChargeBy,
            serviceFeeType:serviceFeeType,
            serviceFeeAmount:serviceFeeAmount
        }

        new RestServer().setUrl(producturl+'/api/servicefee/servicefee')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                setServiceFeeModel(false);
                history('/company', {replace:true})
            }else{
                toast(rtndata.Message);
            }
        });

    }

    const handleUpdateForm = (e)=>{
        e.preventDefault();
        /*
        const data={
            parentcategory:{},
            categoryname: categoryName,
            categorydescription:categoryDescription,
            companyid:userData[0].companyid
        }
        console.info(data);

        
        new RestServer().setUrl(producturl+'/api/cat/category')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                setShowModal(false);
                history('/company', {replace:true})
            }else{
                toast(rtndata.Message);
            }
        });
        */
    }

    useEffect(() => {
        console.info("userData[0].companyid : "+ userData[0].companyid)
        new RestServer().setUrl(orgurl+'/api/org/company/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info(response.data);
            setCompany(response.data);
        });

        new RestServer().setUrl(producturl+'/api/servicefee/allcompanyservicefee/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("allcompanyservicefee");
            setServiceFee(response.data);
        });
        

        new RestServer().setUrl(orgurl+'/api/org/companytaxes/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setTaxes(response.data);
        });

        new RestServer().setUrl(orgurl+'/api/org/companytaxes/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setTaxes(response.data);
        });


        console.info("start allcompanycategory");

        new RestServer().setUrl(producturl+'/api/cat/allcompanycategory/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("allcompanycategory");
            setCategories(response.data);
        });

    },[]);
    return (
        <React.Fragment>
             <Row>
                <Col lg={11} md={11} className="glass-container-1 m-10 p-l-25 p-t-10 p-b-25 p-l-50 ">
                    <Row>
                        <Col lg={1}><img src={complogo}  className="img-cotainer-3 p-t-10 p-b-10"/></Col>
                        <Col className="p-l-50">
                            <Row className="p-t-25">
                                <Col className="font-size-125 font-heavy8">{company?.companyname}</Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col className="font-size-75">{company?.companydescription}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
             </Row>
             <Row className="p-t-25">
                <Col lg={5} md={5} className="glass-container-1 m-10 p-l-25 p-t-10 p-b-25 p-l-50">
                    <Row className=" p-b-25">
                        <Col className="font-size-125 font-heavy8">Taxes</Col>
                        <Col className="font-size-125 font-heavy9 right p-t-10 p-r-25" >
                            <button className="icon-container-1" onClick={()=>{setTaxesModel(true)}}>
                                <FontAwesomeIcon icon={faCirclePlus} />
                            </button>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <Row className="font-heavy font-size-75 p-b-5">
                                <Col lg={3}>Name</Col>
                                <Col>Description</Col>
                                <Col lg={3}>Amount</Col>
                                <Col lg={2}>Actions</Col>
                            </Row>
                            {
                                taxes?.map((tax)=>{
                                    return <Row className="font-size-75">
                                    <Col  lg={3}>{tax.taxName}</Col>
                                    <Col>{tax.taxDescription}</Col>
                                    <Col lg={3}>{tax.percentage}</Col>
                                    <Col lg={2}>
                                        <Row>
                                            <Col>
                                                <button className="icon-text-container-1" onClick={()=>{handleEditTaxForm(tax)}}>
                                                    <FontAwesomeIcon icon={faPen} />
                                                </button>
                                            </Col>
                                            <Col>
                                                <button className="icon-text-container-1"  onClick={()=>{handleTaxDel(tax.companyTaxId)}}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                })
                            }
                           
                        </Col>
                    </Row>
                </Col>
                <Col lg={6} md={5} className="glass-container-1 m-10 p-l-25 p-t-10 p-b-25 p-l-50 m-r-75">
                    <Row className=" p-b-25">
                        <Col className="font-size-125 font-heavy8">Service Fee</Col>
                        <Col className="font-size-125 font-heavy9 right p-t-10 p-r-25" >
                            <button className="icon-container-1" onClick={()=>{setServiceFeeModel(true)}}>
                                <FontAwesomeIcon icon={faCirclePlus} />
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row className="font-heavy font-size-75 p-b-5">
                                <Col>Name</Col>
                                <Col>Description</Col>
                                <Col>Charge By</Col>
                                <Col>Fee Type</Col>
                                <Col>Amount</Col>
                                <Col>Actions</Col>
                            </Row>
                            {
                                serviceFees?.map((servicefee)=>{
                                    return<Row className="font-size-75">
                                        <Col>{servicefee?.serviceFeeName}</Col>
                                        <Col>{servicefee?.serviceFeeDescription}</Col>
                                        <Col>{servicefee?.serviceFeeChargeBy}</Col>
                                        <Col>{servicefee?.serviceFeeType}</Col>
                                        <Col>{servicefee?.serviceFeeAmount}</Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <button className="icon-text-container-1">
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </button>
                                                </Col>
                                                <Col>
                                                    <button className="icon-text-container-1">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                })
                            }
                        </Col>
                    </Row>
                </Col>
                <Col  lg={11} md={11} className="glass-container-1 m-10 p-l-25 p-t-10 p-b-25 p-l-50">
                    <Row className=" p-b-25">
                        <Col className="font-size-125 font-heavy8">Categories</Col>
                        <Col className="font-size-125 font-heavy9 right p-t-10 p-r-25" >
                            <button className="icon-container-1" onClick={()=>{setCategoryModel(true)}}>
                                <FontAwesomeIcon icon={faCirclePlus} />
                               
                            </button>
                        </Col>
                    </Row>
                   
                    <Row >
                        <Col>
                            <Row className="font-heavy font-size-75 p-b-5">
                                <Col>Name</Col>
                                <Col>Description</Col>
                                
                                <Col>Actions</Col>
                            </Row>
                            {
                                categories?.map((cat)=>{
                                    return <Row className="font-size-75 p-t-20">
                                    <Col>{cat.categoryname}</Col>
                                    <Col>{cat.categorydescription}</Col>
                                    
                                    <Col>
                                        <Row>
                                            <Col>
                                                <button className="icon-text-container-1"  onClick={()=>{handleEditCategory(cat)}}>
                                                    <FontAwesomeIcon icon={faPen} />
                                                </button>
                                            </Col>
                                            <Col>
                                                <button className="icon-text-container-1" onClick={()=>{handleCatDel(cat.categoryid)}}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            
                                })
                            }
                           
                        </Col>
                    </Row>
                </Col>

             </Row>

               {/*Categories Model*/}
               <Modal show={categoryModel} onHide={()=>{setCategoryModel(false)}}>
                    <Form onSubmit={handleCategoryForm}>
                        <Modal.Header closeButton>
                        <Modal.Title>Categories</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="categoryname" onChange={(s)=>{setCategoryName(s.target.value)}}>
                                        <Form.Label>Category Name</Form.Label>
                                        <Form.Control className="clr-black"  value={categoryName} type="text" placeholder="Category Name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="categorycode" onChange={(s)=>{setCatCode(s.target.value)}}>
                                        <Form.Label>Category Code</Form.Label>
                                        <Form.Control className="clr-black"  value={catCode} type="text" placeholder="Category Code" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-25 p-b-50">
                                    <Col lg={12}>  
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Category For</Form.Label>
                                            <Form.Control as="select" onChange={(s)=>{setCatUserFor(s.target.value)}}>
                                                <option>Category Usage...</option>
                                                    <option value="INEVENTPRODUCT">Exclusive Product In Event</option>
                                                    <option value="EVENTTICKETS">Event Ticket Types</option>
                                                    <option value="VENDORPRODUCT">Vendor Products Types</option>
                                                    
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            <Row className="p-t-10">
                                    <Col lg={12}>  
                                        <Form.Group controlId ="categorydescrription" onChange={(s)=>{setCategoryDescription(s.target.value)}}>
                                            <Form.Label>Category Description</Form.Label>
                                            <Form.Control as="textarea" value={categoryDescription} rows={4} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{setCategoryModel(false)}}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Create Category
                        </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                 {/*Edit Categories Model*/}
               <Modal show={editCategoryModel} onHide={()=>{setEditCategoryModel(false)}}>
                    <Form onSubmit={handleEditCategoryForm}>
                        <Modal.Header closeButton>
                        <Modal.Title>Categories</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="categoryname" onChange={(s)=>{setCategoryName(s.target.value)}}>
                                        <Form.Label>Category Name</Form.Label>
                                        <Form.Control className="clr-black"  value={categoryName} type="text" placeholder="Category Name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-10">
                                <Col >
                                    <Form.Group controlId ="categorycode" onChange={(s)=>{setCatCode(s.target.value)}}>
                                        <Form.Label>Category Code</Form.Label>
                                        <Form.Control className="clr-black"  value={catCode} type="text" placeholder="Category Code" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="p-t-25 p-b-50">
                                    <Col lg={12}>
                                     
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Category For</Form.Label>
                                            <Form.Control as="select" onChange={(s)=>{setCatUserFor(s.target.value)}}>
                                                <option value={catUserFor}>{catForDes}</option>
                                                    <option value="EVENT">Use For Events</option>
                                                    <option value="PRODUCT">Use For Products</option>
                                                    
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            <Row className="p-t-10">
                                    <Col lg={12}>  
                                        <Form.Group controlId ="categorydescrription" onChange={(s)=>{setCategoryDescription(s.target.value)}}>
                                            <Form.Label>Category Description</Form.Label>
                                            <Form.Control as="textarea" value={categoryDescription} rows={4} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{setCategoryModel(false)}}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Create Category
                        </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

            
             {/*Add Taxes Model*/}
             <Modal show={taxesModel} onHide={()=>{setTaxesModel(false)}}>
                <Form onSubmit={handleTaxForm}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Tax</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="taxname" onChange={(s)=>{setTaxName(s.target.value)}}>
                                    <Form.Label>Tax Name</Form.Label>
                                    <Form.Control className="clr-black"  value={taxName} type="text" placeholder="Tax Name" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                                <Col lg={12}>  
                                    <Form.Group controlId ="taxdescription" onChange={(s)=>{setTaxDescription(s.target.value)}}>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control as="textarea" value={taxDescription} rows={4} />
                                    </Form.Group>
                                </Col>
                            </Row>
                           
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="taxamount" onChange={(s)=>{setTaxAmount(s.target.value)}}>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control className="clr-black" value={taxAmount} type="text" placeholder="Tax amount" />
                                </Form.Group>
                            </Col>
                        </Row>
                       
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setTaxesModel(false)}}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Create Tax
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            {/*Add Service Model*/}
            <Modal show={serviceFeeModel} onHide={()=>{setServiceFeeModel(false)}}>
                <Form onSubmit={handleServiceFeeForm}>
                    <Modal.Header closeButton>
                    <Modal.Title>Add Service Fee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="taxname" onChange={(s)=>{setServiceFeeName(s.target.value)}}>
                                    <Form.Label>Service Fee Name</Form.Label>
                                    <Form.Control className="clr-black"  value={taxName} type="text" placeholder="Service Fee Name" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                                <Col lg={12}>  
                                    <Form.Group controlId ="taxdescription" onChange={(s)=>{setServiceFeeDescription(s.target.value)}}>
                                        <Form.Label>Service Fee Description</Form.Label>
                                        <Form.Control as="textarea" value={taxDescription} rows={4} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="p-t-10">
                                <Col lg={12}>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Service Fee Charged By</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setServiceFeeChargeBy(s.target.value)}}>
                                            <option value={catUserFor}>{catForDes}</option>
                                            <option value="TRANS">Per Transaction</option>
                                            <option value="TICKET">Per Ticket</option>    
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        <Row className="p-t-10">
                            <Col lg={12}>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Service Fee Type</Form.Label>
                                    <Form.Control as="select" onChange={(s)=>{setServiceFeeType(s.target.value)}}>
                                        <option value={catUserFor}>{catForDes}</option>
                                        <option value="PERC">Percentage Amount (%)</option>
                                        <option value="VALUE">Fix Amount ($)</option>    
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                            <Col lg={12}>
                                <Form.Group controlId ="taxname" onChange={(s)=>{setServiceFeeAmount(s.target.value)}}>
                                    <Form.Label>Service Fee Amount</Form.Label>
                                    <Form.Control className="clr-black"  value={taxName} type="text" placeholder="Service Fee Amount" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={()=>{setTaxesModel(false)}}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Create Service Fee
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

             {/*Edit Company info*/}
             <Modal show={showModal} onHide={handleCloseModel}>
                <Form onSubmit={handleUpdateForm}>
                <Modal.Header closeButton>
                <Modal.Title>Company Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="p-t-10">
                        <Col >
                            <Form.Group controlId ="categoryname" onChange={(s)=>{setCompanyName(s.target.value)}}>
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control className="clr-black" type="text" placeholder={company?.companyname} value ={company?.companyname}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="p-t-10">
                        <Col>
                            <Form.Group controlId ="categorydescription" onChange={(s)=>{setCompanyDescription(s.target.value)}}>
                                <Form.Label>Category Description</Form.Label>
                                <Form.Control as="textarea" placeholder ={company?.companydescription} rows={4} />
                            </Form.Group>
                        </Col>
                    </Row> 
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModel}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit">
                    Add Category
                </Button>
                </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
}
export default CompanyInfo;