import React, {useEffect} from 'react';
import {loginurl}  from '../environment';

function Login(){

    useEffect(() => {
        console.info("inside useEffect" )
        console.info(loginurl);
        window.location.href=loginurl
        ;
    });

    return (
        <React.Fragment>
        </React.Fragment>
    );
}
export default Login;