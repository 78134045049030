import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';

import { useNavigate } from "react-router-dom";

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RestServer from '../../components/cRestServer';
import {producturl}  from '../../environment';

import CurrentRevenue from '../datawidget/currentrevenue';
import ProjectedRevenue from '../datawidget/projectedrevenue';
import TicketSold from '../datawidget/ticketsold';
import TicketRedeemed from '../datawidget/ticketredeemed'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTicket, faCalendarCheck, faPlusCircle, faInfoCircle, faPen, faChartPie, faTrash} from '@fortawesome/free-solid-svg-icons';

//STORES
import userDataStore from '../../stores/zuserdatastore';

function ListProducts() {

       //STORE SYSTEMS
       const { userData} = userDataStore();
       const history = useNavigate();

       const [products, setProducts]=useState(null);

       const routeClick=(path)=>{
        history(path, {replace:true})
    }

    const delProduct=(eid)=>{

        console.info("deleteProduct->eid: ");
        console.info(eid);

        new RestServer().setUrl(producturl+'/api/prod/product/'+eid)
        .setMethod('DELETE')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                new RestServer().setUrl(producturl+'/api/prod/allcompanyproducts/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(response=>{
                    

                    setProducts(response.data);
                });
            }else{
                toast(rtndata.Message);
            }
        });
    }
    const DisplayImage=(prod)=>{
        if(prod?.category?.categorycode==="VENDORPRODUCT"){

            const image = prod?.images[0]?.imageloc+prod?.images[0]?.imagename;
            return <img src={image}  className="img-container-1 rad-tl-5 rad-tr-5"/>

        }else if(prod?.category?.categorycode==="INEVENTPRODUCT"){
            
            const image = prod?.images[0]?.imageloc+prod?.images[0]?.imagename;
            return <img src={image}  className="img-container-1 rad-tl-5 rad-tr-5"/>
        }
        else{
            return "";
        }
    }

  
    useEffect(() => {
        new RestServer().setUrl(producturl+'/api/prod/allcompanyproducts/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info(response.data)
            const eventProducts=response.data;
            
            var tempEventProducts=[];

            if(userData[0].usertype=="EV"){
                eventProducts?.map((ep)=>{
                    if(ep?.category?.categorycode==="VENDORPRODUCT"){
                        if(ep?.additionalVendorInfo?.vendorId===userData[0].vendorId){
                            tempEventProducts.push(ep)
                        }
                    }
                });
            }else{
                tempEventProducts=eventProducts
            }
            setProducts(tempEventProducts);
        });
    },[]);

    return (
        <React.Fragment>
        <Row>
            <Col lg={10} md={7}>
                <span className="font-size-100 font-heavy8">Filters :&nbsp;&nbsp;&nbsp;</span>
                <button className="icon-text-container-1 m-r-10 font-heavy font-size-75"><FontAwesomeIcon icon={faCalendarCheck} className="p-r-20"/>Active Event</button>
                <button className="icon-text-container-1 m-l-10 font-heavy font-size-75"><FontAwesomeIcon icon={faCalendarCheck} className="p-r-20"/>No Event</button>
                
             </Col>
             <Col lg={2} md={5} className="right p-r-50">
             <button className="icon-text-container-0 m-l-100 font-heavy font-size-75" onClick={()=>{routeClick("/addproduct")}}>
                <FontAwesomeIcon icon={faPlusCircle} className="p-r-20"/>Add
            </button>
             </Col>
            </Row>
            <Row className="p-l-10 p-t-25">
            {
                products?.map((product)=>{
                   
                    return <Col lg={2} md={5} className="glass-container-1 m-10">
                  
                                <Row className="p-t-10">
                                    <Col>
                                    {DisplayImage(product)}
                                        {/*<img src={image}  className="img-container-1 rad-tl-5 rad-tr-5"/>*/}
                                    </Col>
                                </Row>
                               
                                <Row className="p-t-50 font-size-150 font-heavy p-l-10 p-r-10">
                                    <Col>{product.productName}</Col>
                                </Row>
                                {/*
                                    <Row className="p-t-5 font-size-75 font-heavy p-l-10 p-r-10 clr-primary-4">
                                        <Col>{venue.venueaddress.address1} {venue.venueaddress.city}, {venue.venueaddress.state} {venue.venueaddress.zip}</Col>
                                    </Row>
                                */}
                                <Row className="p-t-25 font-size-75 p-l-10  p-r-10">
                                    <Col>{product.productDescription}</Col>
                                </Row>
                                <Row className="p-t-75 p-b-25 p-l-10  p-r-10">
                                <Col>
                                    <button className="icon-container-1" onClick={()=>{routeClick("/editproduct/"+product.productId)}}>
                                        <FontAwesomeIcon icon={faPen} />
                                        <span className="icon-container-1-title">Edit
                                            <div className="icon-container-1-title-pointer"></div>
                                        </span>
                                    </button>
                                </Col>
                                    
                                    <Col>
                                        <button className="icon-container-1">
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            <span className="icon-container-1-title">Info
                                                <div className="icon-container-1-title-pointer"></div>
                                            </span>
                                        </button>
                                        
                                    </Col>
                                    <Col>
                                        <button className="icon-container-1" onClick={()=>{routeClick("/productdashboard/"+product.productId)}}>
                                            <FontAwesomeIcon icon={faChartPie} />
                                            <span className="icon-container-1-title">Dashboard
                                                <div className="icon-container-1-title-pointer"></div>
                                            </span>
                                      
                                        </button>
                                    </Col>
                                    <Col>
                                        <button className="icon-container-1" onClick={()=>{delProduct(product.productId)}}>
                                            <FontAwesomeIcon icon={faTrash} />
                                            <span className="icon-container-1-title">Delete
                                                <div className="icon-container-1-title-pointer"></div>
                                            </span>
                                        </button>
                                    </Col>
                                </Row>
                        
                            </Col>
                            
                            
                })
            }
               


            </Row>
        
    </React.Fragment>
        /*
        <React.Fragment>
            <Col className="bck-clr-primary-5 rad-5">
                <Row>
                    <Col lg={1} className=" v-center center font-size-300 "><FontAwesomeIcon icon={faTicket} /></Col>
                    <Col lg={6} className="p-t-25">
                        <Row className="font-size-150 p-l-5">
                            <Col>{product.productname}<span className="font-size-50"><a href="#"><FontAwesomeIcon icon={faPen} />&nbsp;&nbsp; &nbsp;Edit Event</a></span></Col></Row>
                        <Row className="font-size-75 font-heavy p-t-10">
                            <Col lg={6}><FontAwesomeIcon icon={faCalendarCheck} />&nbsp; &nbsp; &nbsp;Events Start at: {props.data.startdate}</Col>
                        </Row>
                    </Col>
                    <Col>
                    
                        <Row className="p-b-10">
                            {props.data.widget[0].widget}
                            {props.data.widget[1].widget}
                        </Row>
                    
                    </Col>
                    
                </Row>
                <Row className="p-t-10 p-b-10">
                    <Col className="right font-size-75"> <FontAwesomeIcon icon={faExpandArrowsAlt} />&nbsp; &nbsp; &nbsp;View Full Control Panel</Col>
                </Row>
            </Col>
        </React.Fragment>
        */
    );
}
export default ListProducts;