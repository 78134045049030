import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import {QrScanner} from '@yudiel/react-qr-scanner';


//FONT ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCalendarCheck, faEdit, faInfoCircle, faChartPie, faTrash, faPlusCircle} from '@fortawesome/free-solid-svg-icons';

//INTERNAL COMPONENTS
import RestServer from '../../components/cRestServer';
import {producturl, conurl}  from '../../environment';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//STORE
import userDataStore from '../../stores/zuserdatastore';


function SecurityDashboard() {

    //STORE SYSTEMS
    const { userData} = userDataStore();

    const[initScanModel, setInitScanModel]=useState(false);
    const [events, setEvents]=useState([]);
    const[eventId, setEventId]=useState(null);

    const handleSetEvent=(eid)=>{
        setEventId(eid);
    }

    const handleCloseModel=()=>{
        setInitScanModel(false);
    }

    const processValidateTicket=(resString)=>{

        const ticketData=JSON.parse(resString);

        const data={
            eventId: eventId,
            deviceUserId: userData[0].userid,
            ticketinfo: {
                orderInfo:{
                    orderid: ticketData.orderid,
                },
                ticketInfomation:[
                    {
                        ticketQRInfo:{
                            qrcodeId:ticketData.qrCode
                        }
                    }
                ]
            }
        };

        new RestServer().setUrl(conurl+'/api/cms/validateticket')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            toast(rtndata.Message);
            setInitScanModel(false);
        });

    }

    useEffect(() => {
        new RestServer().setUrl(producturl+'/api/prod/allcompanyevents/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("ListEvents2->useEffect:")
            console.info(response.data)
            
            setEvents(response.data);
        });

    },[]);   

    return (
        <React.Fragment>
             <Row>
                <Col lg={1} md={8}>
                    <span className="font-size-100 font-heavy8">Select Event :&nbsp;&nbsp;&nbsp;</span>
                    
                 </Col>
                 <Col lg={5}>
                 <Form.Group controlId="exampleForm.ControlSelect1">
                    
                    <Form.Control as="select" onChange={(s)=>{handleSetEvent(s.target.value)}} required>
                        <option>Select Event...</option>
                        {
                            events.map((event)=>{
                                return <option value={event.eventId}>{event.eventname}</option>
                            })
                        }
                        <Form.Control.Feedback type="invalid" className="font-size-75">Please select a Event</Form.Control.Feedback>   
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col lg={3}> &nbsp;</Col>
                 <Col lg={2} md={4} className="right p-r-50">
                    <button className="icon-text-container-0 m-l-100 font-heavy font-size-75 p-r-20"  onClick={()=>{setInitScanModel(true)}}>
                        <FontAwesomeIcon icon={faPlusCircle} className="p-r-20"/>Scan Code
                    </button>
                 </Col>
            </Row>
            <Row className="p-t-25">
            <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Sold Today</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$2500</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>

                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Sold This Week</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$5500</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>

                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Sold This Month</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$8500</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>

                <Col lg={2} md={5} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Total So Far</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$15500</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>
                <Col lg={2} md={10} className="glass-container-1 m-10 p-10">
                    <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                        <Col>Total And Outstanding</Col>
                    </Row>
                    <Row className="p-t-25 font-size-200 font-heavy9 p-l-10 p-r-10 center">
                        <Col>$75.50K / $35.00K</Col>
                    </Row>
                    <Row className="p-t-25 p-b-10 font-size-75  p-l-10 p-r-10 center">
                        <Col>This the the total number of tickets that have checked in</Col>
                    </Row>
                </Col>
            </Row>
            <Row className="p-t-50">

                <Col lg={11}>
                    <Row>
                        <Col className="glass-container-1 m-10 p-10">
                            <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                                <Col>Scan History</Col>
                            </Row>
                    
                        </Col>
                    
                
                        <Col className="glass-container-1 m-10 p-10">
                            <Row className="p-t-10 font-size-150 font-heavy8 p-l-10 p-r-10">
                                <Col>Message History</Col>
                            </Row>
                    
                        </Col>

                    </Row>
                </Col>
               

            </Row>

             {/*Add Company Taxes*/}
             <Modal show={initScanModel} onHide={handleCloseModel}>
            <Form >
            <Modal.Header closeButton>
            <Modal.Title>Process Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-t-10">
                    
                <QrScanner
          onDecode={(result) => processValidateTicket(result)}
          onError={(error) => alert(error?.message)}
      />
                </Row>
             
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" >
                Cancel
            </Button>
           
            </Modal.Footer>
            </Form>
        </Modal>
        </React.Fragment>
    );
}
export default SecurityDashboard;