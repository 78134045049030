import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//INTERNAL
import ImageUpload from '../../components/imageupload';
import DateSelector from '../../components/dateselector';
import RestServer from '../cRestServer';
import {orgurl, producturl}  from '../../environment';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faWindowClose, faIdCard, faUsersBetweenLines, faCalendarPlus, faMobile, faMoneyBillWaveAlt, faImages} from '@fortawesome/free-solid-svg-icons';

//STORE
import userDataStore from '../../stores/zuserdatastore';

function AddProductContent() {

    //STORES
    const { userData} = userDataStore();

    const history = useNavigate();



    //FORM Information
    const[productName, setProductName] = useState(null);
    const[productDescription, setProductDescription] = useState(null);
    const[productCategory, setProductCategory]=useState(null);
    const[productCategoryList, setProductCategoryList]=useState(null);

    //ADDITIONAL INFO -BUNDLEOFFERGRAT
    const[gratityAmount, setGratityAmount]=useState(null);


    //ADDITIONAL INFO -INEVENT
    const [events, setEvents]=useState([]);
    const[selectedEventId, setSelectedEventId]=useState(null);


    const[price, setPrice]=useState(null);
    const[venueid, setVenueId]=useState('');

    //Modal Control
    const [venues, setVenues]=useState([]);

    const[tags, setTags]=useState([]);

    const handleTag=(e)=>{
        if(e.keyCode===13){
            var taglist=[]
            tags.map((t)=>{ taglist.push(t)});
            taglist.push(e.target.value);
            setTags(taglist);
            document.getElementById("tagfield").value="";
        }
    }

    const generateAdditionalInfo=()=>{
        if(productCategory ==="BUNDLEOFFERGRAT"){
            return  <Row>
            <Col>
                <Form.Group controlId ="productdescription" onChange={(s)=>{setGratityAmount(s.target.value)}}>
                    <Form.Label>Gratuity Amount</Form.Label>
                    <Form.Control className="clr-black" type="text" placeholder="Enter Grataity Dollar Amount" />
                </Form.Group>
            </Col>
        </Row>
        }


        if(productCategory==="BUNDLEOFFERNONGRAT"){
            return  <Row>
            <Col>
                No Additional information is required
            </Col>
        </Row>
        }

        if(productCategory==="INEVENTPRODUCT"){

            //filter by venue
            var venueEvents=[];
            events?.map((e)=>{

                if(e.venueid===venueid){
                    venueEvents.push(e);
                }
            })
            return <Row>
                <Col lg={12}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Venue Event(s)</Form.Label>
                        <Form.Control as="select" onChange={(s)=>{setSelectedEventId(s.target.value)}}>
                            <option>Select Event...</option>
                            {
                                venueEvents?.map((event)=>{
                                    return <option value={event?.eventId}>{event?.eventname}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </Col>
        </Row>

        }
    }

    const handleCreateProduct = (e)=>{
        e.preventDefault();

        //GET CATEGORY
        var selectedCategory={};
        productCategoryList.map((c)=>{
            if(c.categorycode===productCategory){
                selectedCategory=c;
            }
        })

        var additionalInfo={};
        var additionalVendorInfo={}
        var vendorProduct=false;

        if(productCategory==="BUNDLEOFFERGRAT"){
            additionalInfo={
                gratuityAmount:gratityAmount

            }
        }

        if(selectedCategory.categorycode==="INEVENTPRODUCT"){
            additionalInfo={
                eventId:selectedEventId

            }
        }

        if(selectedCategory.categorycode==="VENDORPRODUCT"){
            vendorProduct=true;
            additionalVendorInfo={
                vendorId:userData[0].vendorId

            }
        }

        const data ={
            productName:productName,
            productDescription:productDescription,
            venueid:venueid,
            eventId:selectedEventId,
            companyid:userData[0].companyid,
            category:selectedCategory,
            additionalInfo:additionalInfo,
            additionalVendorInfo:additionalVendorInfo,
            isVendorProduct:vendorProduct,
            price:price,
            isTaxable:false,
            eventticketrequired:true,
            images:JSON.parse(localStorage.getItem('imagedatastate'))
        }
        console.info(data);

        new RestServer().setUrl(producturl+'/api/prod/product')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            console.info("add Product: ");
            console.info(response);
            const rtndata =response.data;
            if(rtndata.messageTypeID===1){
                history('/products', {replace:true})
            }else{
                toast(rtndata.Message);
            }
        });
    }
    
    useEffect(() => {
        new RestServer().setUrl(orgurl+'/api/org/companyvenues/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            setVenues(response.data);
        });

        new RestServer().setUrl(producturl+'/api/cat/allcompanycategory/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const prodcatdata=response.data;
            console.info("allcompanycategory->prodcatdata:");
            console.info(prodcatdata);
            var prodCatList=[];

            prodcatdata.map((cat)=>{
                if(cat.usedFor==="INEVENTPRODUCT"){
                    prodCatList.push(cat);
                }

                if(cat.usedFor==="VENDORPRODUCT"){
                    prodCatList.push(cat);
                }
            });
            setProductCategoryList(prodCatList);
        });

        new RestServer().setUrl(producturl+'/api/prod/allcompanyevents/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info(response.data)
            setEvents(response.data);
        });
    },[]);
    

    return (
    <React.Fragment>
        <Row>
            <Col lg={11}>
                <Row>

                    <Col lg={4} md={10} className="p-r-25 p-b-25">
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col>Product Information</Col>
                        </Row>
                        <Row className="glass-container-1">
                            <Col>
                                <Row className="p-t-25">
                                    <Col >
                                        <Form.Group controlId ="productname" onChange={(s)=>{setProductName(s.target.value)}}>
                                            <Form.Label>Product Name</Form.Label>
                                            <Form.Control className="clr-black" type="text" placeholder="product name" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="p-t-25">
                                    <Col>
                                        <Form.Group controlId ="productdescription" onChange={(s)=>{setProductDescription(s.target.value)}}>
                                            <Form.Label>Product Description</Form.Label>
                                            <Form.Control as="textarea" rows={4} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="p-t-25">
                                    <Col >
                                        <Form.Group controlId ="price" onChange={(s)=>{setPrice(s.target.value)}}>
                                            <Form.Label>Product Price</Form.Label>
                                            <Form.Control className="clr-black" type="text" placeholder="product price" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="p-t-25">
                                    <Col lg={12}>  
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Venue</Form.Label>
                                            <Form.Control as="select" onChange={(s)=>{setVenueId(s.target.value)}}>
                                                <option>Select Venue...</option>
                                                {
                                                    venues.map((venue)=>{
                                                        return <option value={venue.venueid}>{venue.venuename}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="p-t-25 p-b-50">
                                    <Col lg={12}>  
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Product Category</Form.Label>
                                            <Form.Control as="select" onChange={(s)=>{setProductCategory(s.target.value)}}>
                                                <option>Select Category...</option>
                                                {
                                                    productCategoryList?.map((p)=>{
                                                        return <option value={p?.categorycode}>{p?.categoryname}</option>
                                                    })
                                                }
                                           
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                    <Col  lg={4} md={10} className="p-r-25 p-b-25">
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col>Tags </Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                            <Col>
                                <Row>
                                    <Col><Form.Control  id="tagfield" className="clr-black" type="text" placeholder="tags"  
                                            onKeyDown={(e)=>{handleTag(e)}} defaultValue=""/></Col>
                                </Row>
                                <Row className="p-t-25 p-l-25 p-r-25">
                                    {
                                        tags.map((tag)=>{
                                                    return  <Col className="container-1 center">{tag}</Col>
                                        })
                                    }
                                        
                                </Row>  
                            </Col>
                        </Row>

                        {/**ADDITIONAL INFORMATION */}
                        <Row className="p-t-25 p-b-25 font-size-125 font-heavy9">
                            <Col>Additional Information </Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                            <Col>
                               {generateAdditionalInfo()}
                            </Col>
                        </Row>

                    </Col>


                    <Col lg={4} md={10}>
                        <Row className="p-b-25 font-size-125 font-heavy9">
                            <Col>Images</Col>
                        </Row>
                        <Row className="bck-clr-white p-t-25 p-b-25  glass-container-1">
                            <Col>
                                <Row>
                                    <Col>
                                        <ImageUpload />
                                    </Col>
                                </Row>  
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Col>
            <Col lg={1} className="v-center">
                <Row  className="p-t-50">
                    <Col lg={12} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9" onClick={handleCreateProduct}>
                            <FontAwesomeIcon icon={faPlusSquare} />&nbsp;&nbsp; Create
                        </button>
                    </Col>
                </Row>
                <Row className="p-t-50">
                    <Col lg={12} className="center">
                        <button className="icon-text-container-0 p-10 font-heavy9">
                            <FontAwesomeIcon icon={faWindowClose} />&nbsp;&nbsp; Cancel
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
       
    </React.Fragment>
    );
}

export default AddProductContent;