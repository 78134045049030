import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import { toast } from 'react-toastify';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

//INTERNAL
import RestServer from '../cRestServer';
import {orgurl}  from '../../environment';

//ICONS
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPen,faUser, faPlusCircle, faTrash, faRectangleXmark, faSquareCheck} from '@fortawesome/free-solid-svg-icons';

//STORE
import userDataStore from '../../stores/zuserdatastore';

function UserInfo() {
    const history = useNavigate();

    const[showModal, setShowModal]=useState(false);
    const[showEditUserModel, setShowEditUserModel]=useState(false)
    const[users, setUsers]=useState([]);
    const[vendors, setVendors]=useState([]);
    const[vendorId, setVendorId]=useState('');

    const[emailAddress, setEmailAddress]=useState(null);
    const[userType, setUserType]=useState(null);

    
    const[editUserId, setEditUserId]=useState(null);
    const[editEmailAddress, setEditEmailAddress]=useState(null);
    const[editUserType, setEditUserType]=useState(null);
    const[editUserTypeDD, setEditUserTypeDD]=useState(null);

    const handleTerminateModel=()=>{setShowModal(false);}
    const handleTerminateEditUserModel=()=>{setShowEditUserModel(false);}
    
    


    //STORES
    const { userData} = userDataStore();

    const convertUserType=(userType)=>{
        var rtnType=null;

        if(userType==="VS"){
            rtnType= "Venue Security User"
        }

        if(userType==="VH"){
            rtnType= "Venue Hostess User"
        }

        if(userType==="EV"){
            rtnType= "Event Vendor User"
        }

        if(userType==="OU"){
            rtnType= "Organization User"
        }

        return rtnType;



    }

    const handleEditUserModel=(e)=>{
        console.info("handleEditUserModel")
        users.forEach((u)=>{
            if(u.userid===e){
                console.info("handleEditUserModel2")
                setEditEmailAddress(u?.contact[0]?.emailaddress);
                let ovs=null;
                let oss=null;
                let outs=null;
                if(u.usertype==="VS"){
                    ovs="VS";
                    oss="Venue Security User";
                    outs="VS";

                }else if(u.usertype==="VH") {
                    ovs="VH";
                    oss="Venue Hostess User";
                    outs="VH";
                }else if(u.usertype==="EV"){
                    ovs="EV";
                    oss="Event Vendor User";
                    outs="EV";

                }else{
                    ovs="OU";
                    oss="Organization User";
                    outs="OU";
                    

                }

                const usertypedata={
                    ov:ovs,
                    os:oss,
                    ou:outs
                }
                setEditUserId(u.userid);
                setEditUserTypeDD(usertypedata);
                setEditUserType(usertypedata.ov)
                setShowEditUserModel(true);


            }
        })
        //search for user
    }

    const handleEditForm = (e)=>{
        e.preventDefault();

        const data ={
            userid:editUserId,
            companyId:userData[0].companyid,
            signupType:editUserType,
            email:editEmailAddress,
        }
       
    }

    const handleAddForm = ()=>{
        var data ={};

        if(userType==="EV"){
            data={
                companyId:userData[0].companyid,
                signupType:userType,
                vendorId:vendorId,
                email:emailAddress,
            }

        }else{
            data={
                companyId:userData[0].companyid,
                signupType:userType,
                email:emailAddress,
            }
        }
       
        new RestServer().setUrl(orgurl+'/api/org/usersign')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            console.info(response.data)
            toast(response.data.message);
            setShowModal(false);
            
            new RestServer().setUrl(orgurl+'/api/org/companyusers/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(response=>{
                    const rtn=response.data;
                    setUsers(rtn);   
                });
                
            
        });
    }
    const enableDisable=(id, status)=>{
        const data={
            userid:id,
            isActive:status
        }

        new RestServer().setUrl(orgurl+'/api/org/enabledisableuser')
        .setMethod('PUT')
        .flagReturnData(true)
        .setPostBody(data)
        .connect()
        .then(response=>{
            const rtndata =response.data;
           
            new RestServer().setUrl(orgurl+'/api/org/companyusers/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(resp=>{
            const rtn=resp.data;

            setShowModal(false);
            toast(rtndata.Message);
        
            setUsers(rtn); 
        });
        });
    }

    const vendorAccessCheck=()=>{
        console.info("vendorAccessCheck");
        console.info(userData);
        if(userData[0].usertype==="EV"){
            return true;
        }else{
            return false;
        }
    }

    const handleDeleteUser=(uid)=>{

        if(uid === userData[0].userid){
            toast("Cannot delete user you are login as");

        }else{
            new RestServer().setUrl(orgurl+'/api/org/user/'+uid)
            .setMethod('DELETE')
            .flagReturnData(true)
            .connect()
            .then(res=>{
              console.info(res)
              if(res.data.messageTypeID===1){
                new RestServer().setUrl(orgurl+'/api/org/companyusers/'+userData[0].companyid)
                .setMethod('GET')
                .flagReturnData(true)
                .connect()
                .then(response=>{
                    const rtn=response.data;
                   
                    setUsers(rtn);   
                });
              }else{
                toast(res?.data?.message)
              }
               
               
            });

        }
    }

    


    const renderVendors=()=>{

        if(userType==="EV"){
            return  <Row className="p-t-10">
                <Col lg={12}>  
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>User Type</Form.Label>
                        <Form.Control as="select" onChange={(s)=>{setVendorId(s.target.value)}}>
                            <option >Select Vendors...</option>
                            {
                            vendors.forEach((v)=>{
                                return <option value={v.vendorid}>{v.vendorname}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
        }
    }

    useEffect(() => {
        var toparray=[];
        
        new RestServer().setUrl(orgurl+'/api/org/companyusers/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{

            const rtn=response.data;
            var userD=[];

            if(userData[0].usertype==="EV"){
                rtn.map((r)=>{
                    if(userData[0].vendorId===r.vendorId){
                        userD.push(r);
                    }
                })
              
            }else{
                userD=rtn;
            }
            setUsers(userD);   
        });

        new RestServer().setUrl(orgurl+'/api/org/allcompanyvendor/'+userData[0].companyid)
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            const rtn=response.data;

            var vendorsD=[];
            if(userData[0].usertype==="EV"){

                rtn.map((r)=>{
                    if(userData[0].vendorId===r.vendorid){
                        vendorsD.push(r);
                    }
                })

            }else{
                vendorsD=rtn;
            }

            setVendors(vendorsD);   
        });

    },[]);

    return (
        <React.Fragment>
            <Row>
                <Col  lg={10} md={7}>
                    <span className="font-size-100 font-heavy8">Filters :&nbsp;&nbsp;&nbsp;</span>
                    <button className="icon-text-container-1 m-r-10 font-heavy font-size-75"><FontAwesomeIcon icon={faUser} className="p-r-20"/>Security</button>
                    <button className="icon-text-container-1 m-l-10 font-heavy font-size-75"><FontAwesomeIcon icon={faUser} className="p-r-20"/>Hostess</button>
                 </Col>
                 <Col lg={2} md={5} className="right p-r-50">
                 <button  className="icon-text-container-0 m-l-100 font-heavy font-size-75 p-r-20" onClick={(s)=>{setShowModal(true)}}>
                    <FontAwesomeIcon icon={faPlusCircle} className="p-r-20"/>Add  User
                </button>
                 </Col>
            </Row>
            <Row className="p-l-10 p-t-25 p-b-25">
                {
                    users?.map((d)=>{
                        return <Col lg={2} md={11} className="glass-container-1 m-10">
                                <Row><Col lg={5}>&nbsp;</Col><Col className="p-t-25 p-b-25 font-size-300 font-heavy9"><FontAwesomeIcon icon={faUser}/></Col></Row>
                                
                                <Row><Col><span className="font-heavy">Email :</span> {d.contact[0].emailaddress} </Col></Row>
                                <Row><Col><span className="font-heavy">User Type : </span> {convertUserType(d.usertype)}</Col></Row>
                                <Row><Col><span className="font-heavy">Status : </span> {d.active ? "Active" : "InActive" }</Col></Row>
                                <Row className="p-t-75 p-b-25 p-l-10  p-r-10">
                                    <Col><button  className="icon-container-1" onClick={(s)=>{handleEditUserModel(d.userid)}}><FontAwesomeIcon icon={faPen} /></button></Col>
                                    <Col>
                            {d.active? <button className="icon-container-1" onClick={(i,s)=>{enableDisable(d.deviceid, false)}}>
                                    <FontAwesomeIcon icon={faRectangleXmark} />
                                    <span className="icon-container-1-title">De-Activate
                                        <div className="icon-container-1-title-pointer"></div>
                                    </span>
                                </button>: <button className="icon-container-1"  onClick={(i,s)=>{enableDisable(d.deviceid, true)}}>
                                    <FontAwesomeIcon icon={faSquareCheck} />
                                    <span className="icon-container-1-title">Activate
                                        <div className="icon-container-1-title-pointer"></div>
                                    </span>
                                </button>}
                               
                            </Col>
                                    <Col><button  className="icon-container-1" onClick={(i)=>{handleDeleteUser(d.userid)}}><FontAwesomeIcon icon={faTrash} /></button></Col>
                                </Row>
                            </Col>
                    })
                }
            </Row>
             {/*Add User Model*/}
             <Modal show={showModal} onHide={handleTerminateModel}>
                
                    <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="emailaddress" onChange={(s)=>{setEmailAddress(s.target.value)}}>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control className="clr-black" type="text" placeholder="Email Address" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="p-t-10">
                        {((vendorAccessCheck())?<Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>User Type</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setUserType(s.target.value)}}>
                                            <option >Select User Type...</option>
                                            <option value="EV">Event Vendor User</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>: <Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>User Type</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setUserType(s.target.value)}}>
                                            <option >Select User Type...</option>
                                            <option value="VS">Venue Security User</option>
                                            <option value="VH">Venue Hostess User</option>
                                            <option value="EV">Event Vendor User</option>
                                            <option value="OU">Organization User</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>)}
                               
                            </Row>
                            {renderVendors()}
                        <Row className="p-t-25"></Row>
                        
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleTerminateModel}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={()=>{handleAddForm()}}>
                        Create User
                    </Button>
                    </Modal.Footer>
            </Modal>


             {/*Edit User Model*/}
             <Modal show={showEditUserModel} onHide={handleTerminateEditUserModel}>
                <Form onSubmit={handleEditForm}>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="p-t-10">
                            <Col >
                                <Form.Group controlId ="emailaddress" onChange={(s)=>{setEditEmailAddress(s.target.value)}}>
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control className="clr-black" value={editEmailAddress} type="text" placeholder="Email Address" />
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <Row className="p-t-10">
                        {((vendorAccessCheck())?<Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>User Type</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setUserType(s.target.value)}}>
                                            <option >Select User Type...</option>
                                            <option value="EV">Event Vendor User</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>: <Col lg={12}>  
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>User Type</Form.Label>
                                        <Form.Control as="select" onChange={(s)=>{setUserType(s.target.value)}}>
                                            <option >Select User Type...</option>
                                            <option value="VS">Venue Security User</option>
                                            <option value="VH">Venue Hostess User</option>
                                            <option value="EV">Event Vendor User</option>
                                            <option value="OU">Organization User</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>)}
                               
                            </Row>
                            {renderVendors()}
                        <Row className="p-t-25">
                           
                        </Row>
                        
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleTerminateEditUserModel}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Update User
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
}
export default UserInfo;