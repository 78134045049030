import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom"


import Login from './containers/login';
import Signup from './containers/signup';
import LoginRedirect from './containers/loginredirect';


import CompanyDashboard from './containers/company/companydashboard';
import EditCompany from './containers/company/editcompany';

import EventList from './containers/event/eventlist';
import EventDashboard from './containers/event/eventdashboard';
import AddEvent from './containers/event/addevent';
import EditEvent from './containers/event/editevent';

import ProductList from './containers/products/productlist';
import ProductDashboard from './containers/products/productdashboard';
import AddProduct from './containers/products/addproduct';
import EditProduct from './containers/products/editproduct';


import VenueList from './containers/venue/venuelist';
import VenueDashboard  from './containers/venue/venuedashboard';
import AddVenue from './containers/venue/addvenue';
import EditVenue from './containers/venue/editvenue';

import DeviceDashboard from './containers/devices/devicedashboard';
import UserDashboard from './containers/user/userdashboard';
import EditUserProfile from './containers/user/edituserprofile';

import ChatDashboard from './containers/chat/chatdashboard';

import VendorDashboard from './containers/vendor/vendordashboard';
import AddVendor from './containers/vendor/addvendor';
import EditVendor from "./containers/vendor/editvendor"

import VendorPosDashboard from './containers/vendorpos/vendorposdashboard';

import SecurityScanTickets from './containers/securityprocess/securityscantickets';

function Main(){
    return(
        <Router>
            <Routes>

                <Route path="/events" element={<EventList />} />
                <Route path="/eventdashboard/:id" element={<EventDashboard />} />
                <Route path="/addevent" element={<AddEvent />} />
                <Route path="/editevent/:id" element={<EditEvent />} />

                <Route path="/venues" element={<VenueList />} />
                <Route path="/venuedashboard/:id" element={<VenueDashboard />} />
                <Route path="/addvenue" element={<AddVenue />} />
                <Route path="/editvenue/:id" element={<EditVenue />} />

                <Route path="/products" element={<ProductList />} />
                <Route path="/productdashboard/:id" element={<ProductDashboard />} />
                <Route path="/addproduct" element={<AddProduct />} />
                <Route path="/editproduct/:id" element={<EditProduct />} />

                {/* Security Services*/}
                <Route path="/securityscantickets" element={<SecurityScanTickets />} />
                
                

                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/company" element={<CompanyDashboard />} />
                <Route path="/editcompany" element={<EditCompany />} />
                <Route path="/device" element={<DeviceDashboard />} />
                <Route path="/users" element={<UserDashboard />} />
                <Route path="/edituserprofile" element={<EditUserProfile />} />

                <Route path="/vendors" element={<VendorDashboard />} />
                <Route path="/addvendor" element={<AddVendor />} />
                <Route path="/editvendor/:id" element={<EditVendor />} />


                <Route path="/vendorpos" element={<VendorPosDashboard />} />

                <Route path="/chatservices" element={<ChatDashboard />} />
                <Route path="/loginredirect" element={<LoginRedirect />} />


            </Routes>
        </Router>
    );
}
export default Main;